.game-type-modal {
position: fixed;
z-index: 1000;
left: 0;
top: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
}

.game-type-content {
background-color: rgba(10, 0, 63, 0.6);
padding: 1.5rem;
border-radius: 10px;
text-align: center;
color: white;
box-shadow: inset 0 0 100px black;
width: 90%;
max-width: 500px;
}

.game-type-content h2 {
font-size: 1.25rem;
margin-bottom: 1.25rem;
color: #d7fefd;
filter: drop-shadow(0 0px 5px #5f6ece);
}

.game-type-buttons {
display: flex;
flex-direction: column;
align-items: center;
gap: 15px;
}

.game-type-button {
display: flex;
flex-direction: column;
align-items: center;
background-color: transparent;
cursor: pointer;
transition: all 0.5s;
width: 100%;
pointer-events: all;
}

.game-type-button-outer {
border: 2px #FFF6 solid;
padding: 3px 3px;
border-radius: 10px;
transition: border-radius 0.5s, border 0.5s, padding 0.5s;
width: 100%;
}

.game-type-button-inner {
border: 2px #FFF solid;
border-radius: 6px;
padding: 15px 10px;
background-color: transparent;
transition: background-color 0.2s;
width: 100%;
}

.game-type-button-outer:hover .game-type-button-inner,
.game-type-button-inner:hover {
background-color: #fff4;
}

.game-type-icon {
width: 48px;
height: 48px;
margin-bottom: 0.5rem;
}

.game-type-button span {
color: #d7fefd;
filter: drop-shadow(0 0px 5px #5f6ece);
font-size: 0.9rem;
}

.game-type-button-outer:hover .game-type-button span {
transform: scale(1.05);
}

@media (min-width: 400px) {
    .game-type-content {
        padding: 2rem;
    }

    .game-type-content h2 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .game-type-buttons {
        flex-direction: row;
        justify-content: space-around;
    }

    .game-type-button {
    }

    .game-type-button-inner {
        padding: 20px 10px;
    }

    .game-type-icon {
        width: 64px;
        height: 64px;
    }

    .game-type-button span {
        font-size: 1rem;
    }
}