.game-type-modal {
  z-index: 1000;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.game-type-content {
  text-align: center;
  color: #fff;
  background-color: #0a003f99;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  padding: 1.5rem;
  box-shadow: inset 0 0 100px #000;
}

.game-type-content h2 {
  color: #d7fefd;
  filter: drop-shadow(0 0 5px #5f6ece);
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
}

.game-type-buttons {
  flex-direction: column;
  align-items: center;
  gap: 15px;
  display: flex;
}

.game-type-button {
  cursor: pointer;
  pointer-events: all;
  background-color: #0000;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: all .5s;
  display: flex;
}

.game-type-button-outer {
  border: 2px solid #fff6;
  border-radius: 10px;
  width: 100%;
  padding: 3px;
  transition: border-radius .5s, border .5s, padding .5s;
}

.game-type-button-inner {
  background-color: #0000;
  border: 2px solid #fff;
  border-radius: 6px;
  width: 100%;
  padding: 15px 10px;
  transition: background-color .2s;
}

.game-type-button-outer:hover .game-type-button-inner, .game-type-button-inner:hover {
  background-color: #fff4;
}

.game-type-icon {
  width: 48px;
  height: 48px;
  margin-bottom: .5rem;
}

.game-type-button span {
  color: #d7fefd;
  filter: drop-shadow(0 0 5px #5f6ece);
  font-size: .9rem;
}

.game-type-button-outer:hover .game-type-button span {
  transform: scale(1.05);
}

@media (width >= 400px) {
  .game-type-content {
    padding: 2rem;
  }

  .game-type-content h2 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }

  .game-type-buttons {
    flex-direction: row;
    justify-content: space-around;
  }

  .game-type-button-inner {
    padding: 20px 10px;
  }

  .game-type-icon {
    width: 64px;
    height: 64px;
  }

  .game-type-button span {
    font-size: 1rem;
  }
}
/*# sourceMappingURL=index-solo.1d8339f7.css.map */
